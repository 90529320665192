import React from 'react'

export const ContentMediaSlice = ({ slice }) => {
  const primaryData = slice.primary;
  
  if (primaryData.contentmedia_title.text && primaryData.contentmedia_image.url) {
    return (
      <div className={`c-content-media ${primaryData.contentmedia_image_alignment === "Left" ? "c-content-media--invert" : ""}`}>
          <div className="container">
              <div className="row">
                  <div className="c-content-media__content-container">
                      {primaryData.contentmedia_subtitle.text ? 
                        <div className="c-content-media__subtitle">{primaryData.contentmedia_subtitle.text}</div> : null}
                      <h2 className="c-content-media__title">{primaryData.contentmedia_title.text}</h2>
                      {primaryData.contentmedia_content.text ?
                        <div className="c-content-media__content">
                            {primaryData.contentmedia_content.text}
                        </div> : null}
                        {primaryData.contentmedia_button_text.text && primaryData.contentmedia_button_link.url ?
                          <div className="c-content-media__btn-container">
                              <a href={primaryData.contentmedia_button_link.url} className="c-content-media__btn c-btn c-btn--large">{primaryData.contentmedia_button_text.text}</a>
                          </div> : null}
                  </div>
                  <div className="c-content-media__media-container">
                      <img src={`${primaryData.contentmedia_image.url}&w=800`} alt="" className="c-content-media__media" loading="lazy" />
                  </div>
              </div>
          </div>
      </div>
    )
  }

  return null
}